// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-video{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    padding: 6vh 12vw;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
}
.modal-video-movie-wrap{
    text-align: center;
    padding:0 !important;
    
}
.modal-video-movie-wrap iframe{
    width: 64vw;
    height: 36vw;
}

.modal-video-inner{
    width:100% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/home/modal-video.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,MAAM;IACN,OAAO;IACP,8BAA8B;IAC9B,iBAAiB;IACjB,aAAa;IACb,aAAa;IACb,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,oBAAoB;;AAExB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".modal-video{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    background: rgba(0, 0, 0, 0.9);\n    padding: 6vh 12vw;\n    z-index: 1000;\n    height: 100vh;\n    width: 100vw;\n}\n.modal-video-movie-wrap{\n    text-align: center;\n    padding:0 !important;\n    \n}\n.modal-video-movie-wrap iframe{\n    width: 64vw;\n    height: 36vw;\n}\n\n.modal-video-inner{\n    width:100% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
