// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background-color: ivory;
    padding-top:100px;
  }
  
  .styled-form {
    background-color: rgb(7, 107, 55);
    border: 2px solid rgb(7, 107, 55);
    border-radius: 8px;
    padding: 20px;
    width: 85vw;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .styled-form h2{
    text-align: center;
    color: #EFC800;
  }
  
  .styled-form input, 
  .styled-form select, 
  .styled-form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid rgb(7, 107, 55);
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .styled-form textarea {
    resize: none;
    height: 100px;
  }
  
  /* .styled-form button {
    width: 100%;
    padding: 10px;
    background-color: rgb(7, 107, 55);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  } */
  
  .styled-form button:hover {
    background-color: rgb(5, 80, 40);
  }
  
  .styled-form input:focus, 
  .styled-form select:focus, 
  .styled-form textarea:focus {
    outline: none;
    border-color: rgb(5, 80, 40);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/common/reg.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;EACnB;;EAEA;IACE,iCAAiC;IACjC,iCAAiC;IACjC,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,0CAA0C;EAC5C;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB;;EAEA;;;IAGE,WAAW;IACX,aAAa;IACb,cAAc;IACd,iCAAiC;IACjC,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;;;;;;;;;KASG;;EAEH;IACE,gCAAgC;EAClC;;EAEA;;;IAGE,aAAa;IACb,4BAA4B;EAC9B","sourcesContent":[".form-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    /* height: 100vh; */\n    background-color: ivory;\n    padding-top:100px;\n  }\n  \n  .styled-form {\n    background-color: rgb(7, 107, 55);\n    border: 2px solid rgb(7, 107, 55);\n    border-radius: 8px;\n    padding: 20px;\n    width: 85vw;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n  }\n  \n  .styled-form h2{\n    text-align: center;\n    color: #EFC800;\n  }\n  \n  .styled-form input, \n  .styled-form select, \n  .styled-form textarea {\n    width: 100%;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid rgb(7, 107, 55);\n    border-radius: 4px;\n    box-sizing: border-box;\n  }\n  \n  .styled-form textarea {\n    resize: none;\n    height: 100px;\n  }\n  \n  /* .styled-form button {\n    width: 100%;\n    padding: 10px;\n    background-color: rgb(7, 107, 55);\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n  } */\n  \n  .styled-form button:hover {\n    background-color: rgb(5, 80, 40);\n  }\n  \n  .styled-form input:focus, \n  .styled-form select:focus, \n  .styled-form textarea:focus {\n    outline: none;\n    border-color: rgb(5, 80, 40);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
