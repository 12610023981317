// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  #gallery{
    column-count:4;
    column-gap:20px;
  }
  @media (max-width:1200px){
    #gallery{
    column-count:3;
    column-gap:20px;
  }
  }
  @media (max-width:800px){
    #gallery{
    column-count:2;
    column-gap:20px;
  }
  }
  /* @media (max-width:600px){
    #gallery{
    -webkit-column-count:1;
    -moz-column-count:1;
    column-count:1;
  }  
  } */
  #gallery img,#gallery video {
    width:100%;
    height:auto;
    margin: 4% auto;
    box-shadow:-3px 5px 15px #000;
    cursor: pointer;
    transition: all 0.2s;
  }
  /* .modal-img,.model-vid{
    width:100%;
    height:auto;
  }
  .modal-body{
    padding:0px;
  } */`, "",{"version":3,"sources":["webpack://./src/pages/gallery.css"],"names":[],"mappings":";EACE;IAGE,cAAc;IAId,eAAe;EACjB;EACA;IACE;IAGA,cAAc;IAId,eAAe;EACjB;EACA;EACA;IACE;IAGA,cAAc;IAId,eAAe;EACjB;EACA;EACA;;;;;;KAMG;EACH;IACE,UAAU;IACV,WAAW;IACX,eAAe;IACf,6BAA6B;IAC7B,eAAe;IAEf,oBAAoB;EACtB;EACA;;;;;;KAMG","sourcesContent":["\n  #gallery{\n    -webkit-column-count:4;\n    -moz-column-count:4;\n    column-count:4;\n    \n    -webkit-column-gap:20px;\n    -moz-column-gap:20px;\n    column-gap:20px;\n  }\n  @media (max-width:1200px){\n    #gallery{\n    -webkit-column-count:3;\n    -moz-column-count:3;\n    column-count:3;\n      \n    -webkit-column-gap:20px;\n    -moz-column-gap:20px;\n    column-gap:20px;\n  }\n  }\n  @media (max-width:800px){\n    #gallery{\n    -webkit-column-count:2;\n    -moz-column-count:2;\n    column-count:2;\n      \n    -webkit-column-gap:20px;\n    -moz-column-gap:20px;\n    column-gap:20px;\n  }\n  }\n  /* @media (max-width:600px){\n    #gallery{\n    -webkit-column-count:1;\n    -moz-column-count:1;\n    column-count:1;\n  }  \n  } */\n  #gallery img,#gallery video {\n    width:100%;\n    height:auto;\n    margin: 4% auto;\n    box-shadow:-3px 5px 15px #000;\n    cursor: pointer;\n    -webkit-transition: all 0.2s;\n    transition: all 0.2s;\n  }\n  /* .modal-img,.model-vid{\n    width:100%;\n    height:auto;\n  }\n  .modal-body{\n    padding:0px;\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
