// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-right: 103px; */
  }
  
  .timeline {
    height: 250px;
    width: 5px;
    background-color: #e5e5e5;
  }
  
  .stub1 {
    line-height: 100px;
    font-size: 32px;
    font-family: 'Lexend Semibold';
    background-color: #eae4e4;
    text-align: center;
    color:#fff;
  }
  
  .stub2 {
    /* height: 1000px; */
  }
  
  .circle {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    background-color: #e5e5e5;
  }
  
  .circleWrapper {
    position: relative;
  }
  
  .message {
    position: absolute;
    top: 20%;
    left: 135%;
    min-width: 300px;
    font-size:24px;
    line-height: normal;
    font-family: 'Lexend Bold';
    font-weight: bold;
    transition: all 0.3s ease-in-out 0s;
  }
  
  @media(max-width:540px){

    .wrapper {    margin-right: 153px;
    }
  .message {
  font-size:20px;
  min-width:200px;
  }
  .stub1 {
  font-size:24px;
  line-height:50px;
  }
  }`, "",{"version":3,"sources":["webpack://./src/pages/about.css"],"names":[],"mappings":";;EAEE;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,UAAU;IACV,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,8BAA8B;IAC9B,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,0BAA0B;IAC1B,iBAAiB;IACjB,mCAAmC;EACrC;;EAEA;;IAEE,cAAc,mBAAmB;IACjC;EACF;EACA,cAAc;EACd,eAAe;EACf;EACA;EACA,cAAc;EACd,gBAAgB;EAChB;EACA","sourcesContent":["\n  \n  .wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    /* margin-right: 103px; */\n  }\n  \n  .timeline {\n    height: 250px;\n    width: 5px;\n    background-color: #e5e5e5;\n  }\n  \n  .stub1 {\n    line-height: 100px;\n    font-size: 32px;\n    font-family: 'Lexend Semibold';\n    background-color: #eae4e4;\n    text-align: center;\n    color:#fff;\n  }\n  \n  .stub2 {\n    /* height: 1000px; */\n  }\n  \n  .circle {\n    width: 30px;\n    height: 30px;\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    border-radius: 50%;\n    background-color: #e5e5e5;\n  }\n  \n  .circleWrapper {\n    position: relative;\n  }\n  \n  .message {\n    position: absolute;\n    top: 20%;\n    left: 135%;\n    min-width: 300px;\n    font-size:24px;\n    line-height: normal;\n    font-family: 'Lexend Bold';\n    font-weight: bold;\n    transition: all 0.3s ease-in-out 0s;\n  }\n  \n  @media(max-width:540px){\n\n    .wrapper {    margin-right: 153px;\n    }\n  .message {\n  font-size:20px;\n  min-width:200px;\n  }\n  .stub1 {\n  font-size:24px;\n  line-height:50px;\n  }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
