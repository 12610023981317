// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion__button{
    text-align-last: justify;
    opacity: 0.9;
}
.accordion__button:hover{
opacity: 1;
}

.accordion__button:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/navigation/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,YAAY;AAChB;AACA;AACA,UAAU;AACV;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,qCAAqC;IACrC,oCAAoC;IACpC,yBAAyB;AAC7B;;AAEA;;IAEI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".accordion__button{\n    text-align-last: justify;\n    opacity: 0.9;\n}\n.accordion__button:hover{\nopacity: 1;\n}\n\n.accordion__button:after {\n    display: inline-block;\n    content: '';\n    height: 10px;\n    width: 10px;\n    margin-right: 12px;\n    border-bottom: 2px solid currentColor;\n    border-right: 2px solid currentColor;\n    transform: rotate(-45deg);\n}\n\n.accordion__button[aria-expanded='true']::after,\n.accordion__button[aria-selected='true']::after {\n    transform: rotate(45deg);\n}\n\n[hidden] {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
